import { appConstants } from '../helpers';
import axios from 'axios';

export const batchService = {
	batchList,
	createBatch,
	getBatchById,
	updateBatch
};

function batchList() {

	return axios.get(`${appConstants.BASE_URL}api/batch`)
		.then(batch => {
			if(batch.data.code===200){
				return batch.data.data;
			}
			else {
				return batch.data;
			}
		});
}

function createBatch(data) {

	return axios.post(`${appConstants.BASE_URL}api/batch/create`,data)
		.then(batch => {
			if(batch.data.code===201){
				return batch.data.data;
			}
			else {
				return batch.data;
			}
		});
}

function getBatchById(id) {

	return axios.get(`${appConstants.BASE_URL}api/batch/show/${id}`)
		.then(batch => {
			if(batch.data.code===200){
				return batch.data.data;
			}
			else {
				return batch.data;
			}
		});
}

/* updateBatch */
function updateBatch(data) {

	return axios.post(`${appConstants.BASE_URL}api/batch/update`,data)
		.then(batch => {
			if(batch.data.code===201){
				return batch.data.data;
			}
			else {
				return batch.data;
			}
		});
}

