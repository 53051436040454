import React, { Component } from 'react';
import { connect } from 'react-redux';

import { technologyActions } from '../../actions';



class Technology extends Component {

  constructor(){
    super();
    // this.addCategory = this.addCategory.bind(this);
    this.showAlert = this.showAlert.bind(this);
  }

  componentDidMount(){
    this.props.technologyList();
  }

  showAlert(){
    alert('Under Development!');
  }

  render(){
    const technologyItems = this.props.technologyItems;
    
    return(
      <>
        <div className="container my-3">
          <div className="row">
            <div className="col-md-6 offset-1">
              <h3 className="my-3">Technologies<button onClick={this.showAlert} className="btn btn-outline-info mx-2"><img src="./images/add.png" alt="edit icon" height="20" width="20"/>&nbsp;Create</button></h3>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    {/* <th scope="col">Status</th> */}
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {technologyItems?(
                  <tbody>   
                    {technologyItems.map((item,index)=>(               
                  <tr key={index}>
                    <th scope="row">{index+1}</th>
                    <td>{item.name}</td>
                    {/* <td>{item.status===true ? 'Active' : 'Inactive'}</td> */}
                    <td onClick={()=>{alert('Under Development!')}}>Add/ Edit</td>
                  </tr>
                   ))}
                  </tbody>
                ):(
                  <tbody>   
                    <tr>
                    <td colSpan="3">Loading...</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapState(state){
  const { technologyItems } = state.technology
  return { technologyItems }
}

const actionCreators = {
  technologyList : technologyActions.technologyList
}

const connectedTechnologyPage = connect(mapState,actionCreators)(Technology);
export { connectedTechnologyPage as Technology };