import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import  FormValidator from '../../../helpers/FormValidator';
import { courseActions, technologyActions } from '../../../actions';
import { Editor } from '@tinymce/tinymce-react';


class AddCourse extends Component {

  constructor(){
    super();

  this.validator = new FormValidator([
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter course name to proceed'
      },
      {
        field: 'introduction',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter introduction to proceed'
      },
      {
        field: 'duration',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter duration to proceed'
      },
      {
        field: 'projects',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter projects to proceed'
      },
      {
        field: 'courseType',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter course type proceed'
      }
    ]);


    this.state = {
      id : '',
      name : '',
      duration : '',
      projects : '',
      courseType : '',
      introduction : '',
      selectedFile:null,
      submitted : false,
      redirect : 'courses',
      courseTypeName : '',
      editorRef: React.createRef(null),
      validation: this.validator.valid()
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.addCourse = this.addCourse.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount(){
    const query = new URLSearchParams(this.props.location.search);
    if(query.get('id')){
      this.setState({
        id :query.get('id')
      });  
    }
    if(query.get('id')){
      this.props.getCourseById(query.get('id'));
      

    }
    this.props.technologyList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.courseItem && this.props.courseItem !== prevProps.courseItem){
      this.setState({
        name : this.props?.courseItem?.course_title,
        duration : this.props.courseItem.duration,
        projects : this.props.courseItem.projects,
        courseType : this.props.courseItem.technology_id,
        courseTypeName : this.props.courseItem.name,
        introduction : this.props.courseItem.introduction,
        description: this.props.courseItem.description,
      })
    }
    
}

  handleInputChange(e) {
		let name = e.target.name;
    let value = e.target.value;
		this.setState({
			[name]: value
    });
  }

  // On file select...
  onFileChange(event) {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  addCourse(e){
    e.preventDefault();
    const validation = this.validator.validate(this.state);
    this.setState({ submitted: true, validation:validation });
    if (validation.isValid) {
      const formData = new FormData();
      // const course_title_id = strtolower(str_replace(' ', '-', this.state.name));
      if(this.state.selectedFile && this.state.selectedFile.name){
        formData.append("file", this.state.selectedFile, this.state.selectedFile.name);
      }
      formData.append("course_title", this.state.name);
      formData.append("course_title_id", this.state.name.split(" ").join("-").toLowerCase());
      formData.append("duration", this.state.duration);
      formData.append("projects", this.state.projects);
      formData.append("technology_id", this.state.courseType);
      formData.append("introduction", this.state.introduction);
      formData.append("description", this.state.editorRef.current.getContent());
      if(this.state.id){
        formData.append("course_id", this.state.id);
        this.props.updateCourse(formData);
      }
      else {
        this.props.addCourse(formData);
      }
    }
  }

  componentWillUnmount(){
    this.props.clearCourse(); //example method to clean data
 }

  render(){
    const technologyItems = this.props.technologyItems;
    
    if(this.props.courseAdded==="success" || this.props.courseUpdated==="success"){
      alert(this.state.id ? "Course Updated Successfully" : "Course Added Successfully");
      return <Redirect to={this.state.redirect} />
    }

    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation
    return(
      <>
        <div className="container my-3">
          <h3 className="my-3">{this.state.id ?( <span>Edit</span>) :(<span>Add</span>)} Course</h3>
          <form>
            <div className="row">
                
              <div className="col-md-6">
                <div className="form-group text-left" >
                  <label htmlFor="name">Name</label>

                  <input type="text" id="name" className={validation.name.isInvalid ? 'form-control border-left-0 border-top-0 border-right-0 rounded-0 alert-warning' : 'form-control border-left-0 border-top-0 border-right-0 rounded-0'}
                    placeholder="Enter name"  value={this.state.name || ''} onChange={this.handleInputChange} name="name"/>
                    <span className="text-danger text-danger-validation">{validation.name.message}</span>
                </div>
              </div>
        
              <div className="col-md-3">
                <div className="form-group text-left" >
                  <label htmlFor="name">Duration</label>
                  <input type="text" className={validation.duration.isInvalid ? 'form-control border-left-0 border-top-0 border-right-0 rounded-0 alert-warning' : 'form-control border-left-0 border-top-0 border-right-0 rounded-0'}
                    placeholder="Enter duration"  value={this.state.duration} onChange={this.handleInputChange} name="duration"/>
                    <span className="text-danger text-danger-validation">{validation.duration.message}</span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group text-left" >
                    <label htmlFor="name">Projects</label>
                    <input type="text" className={validation.projects.isInvalid ? 'form-control border-left-0 border-top-0 border-right-0 rounded-0 alert-warning' : 'form-control border-left-0 border-top-0 border-right-0 rounded-0'}
                      placeholder="Enter projects count"  value={this.state.projects} onChange={this.handleInputChange} name="projects"/>
                      <span className="text-danger text-danger-validation">{validation.projects.message}</span>
                  </div>
              </div>
              <div className="col-md-4">
                <div className="form-group text-left" >
                  <label htmlFor="name">Technology</label>
                  {technologyItems?(
                    <select className="form-control" value={this.state.courseType} onChange={this.handleInputChange} name="courseType">
                      <option>-Select-</option>
                      {technologyItems.map((item,index)=>(
                        <option key={index}  value={item.technology_id}>{item.name}</option>
                      ))}
                      
                    </select>
                  ):(
                    <p>Loading...</p>
                  )}
                  <span className="text-danger text-danger-validation">{validation.projects.message}</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group text-left" >
                  <label htmlFor="name">Avatar</label>
                  <input type="file" className="form-control border-left-0 border-top-0 border-right-0" name="avatar" onChange={this.onFileChange} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group text-left" >
                  <label htmlFor="name">Introduction</label>
                  <textarea className={validation.introduction.isInvalid ? 'form-control border-left-0 border-top-0 border-right-0 rounded-0 alert-warning' : 'form-control border-left-0 border-top-0 border-right-0 rounded-0'}
                    placeholder="Enter name"  value={this.state.introduction} onChange={this.handleInputChange} name="introduction"></textarea>
                    <span className="text-danger text-danger-validation">{validation.introduction.message}</span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group text-left" >

                  <label htmlFor="name">Description</label>
                  <Editor
                  onInit={(evt, editor) => this.state.editorRef.current = editor}
                initialValue={this.state.description}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
                </div>
              </div>


              <div className='clearfix'></div>
              <p className="text-center mt-4">
                <button type="button" onClick={this.addCourse} className="btn btn-primary mx-2">Submit</button>
              </p>
                {/* disabled={loading ? true : false}  */}
            {/* </div> */}
          </div>
          </form>

        </div>
      </>
    )
  }
}

function mapState(state){
  const { technologyItems } = state.technology;
  const { courseAdded, courseUpdated, courseItem } = state.course;
  return { technologyItems, courseAdded, courseUpdated, courseItem }
}

const actionCreators = {
  addCourse : courseActions.addCourse,
  updateCourse : courseActions.updateCourse,
  getCourseById : courseActions.getCourseById,
  clearCourse: courseActions.clearCourse,
  technologyList : technologyActions.technologyList
}

export default connect(mapState,actionCreators)(AddCourse);
