import React, {Component} from 'react';

import { Switch, Router } from "react-router-dom";
/** Layouts **/  
import LoginLayoutRoute from "./layouts/LoginLayout";  
import LoggedInLayoutRoute from "./layouts/LoggedInLayout"; 

/** Components  **/
import { Login } from './components/auth/Login';
import { Register } from './components/auth/Register';

import { Dashboard } from './components/core/Dashboard';
import { Technology } from './components/core/Technolgoy';
import  Courses  from './components/core/course/Courses';
import  AddCourse  from './components/core/course/Add-Course';
import  Batches  from './components/core/batch/batches';
import  CreateBatch  from './components/core/batch/create-batch';
import { Users } from './components/core/user/users';
import { Leads } from './components/core/lead/leads';
import { BlogList } from './components/core/blog/blog-list';

// import { PrivateRoute } from './components/PrivateRoute';
import { history } from './helpers';
import createBlog from './components/core/blog/create-blog';


class App extends Component {
  // constructor(props){
  //   super(props);
  // }

  render(){
    return(
    <Router history={history}>
      <Switch>
        <LoggedInLayoutRoute exact path="/" component={Dashboard} />  
        <LoggedInLayoutRoute exact path="/technology" component={Technology} />
        <LoggedInLayoutRoute exact path="/courses" component={Courses} />
        <LoggedInLayoutRoute exact path="/add-course" component={AddCourse} />
        <LoggedInLayoutRoute exact path="/batches" component={Batches} />
        <LoggedInLayoutRoute exact path="/create-batch" component={CreateBatch} />
        <LoggedInLayoutRoute exact path="/users" component={Users} />
        <LoggedInLayoutRoute exact path="/leads" component={Leads} />
        <LoggedInLayoutRoute exact path="/blog" component={BlogList} />
        <LoggedInLayoutRoute exact path="/create-blog" component={createBlog} />
        <LoginLayoutRoute exact path="/login" component={Login} />
        <LoginLayoutRoute path="/register" component={Register} />
        

        {/* <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute exact path="/technology" component={Technology} />
        <PrivateRoute exact path="/courses" component={Courses} />
        <PrivateRoute exact path="/add-course" component={AddCourse} />
        <PrivateRoute exact path="/batches" component={Batches} />
        <PrivateRoute exact path="/create-batch" component={CreateBatch} />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/leads" component={Leads} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} /> */}
      </Switch>
    </Router>
    )
  }
}
export default App;
