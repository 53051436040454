import React, { useState, useEffect } from "react";
import { appConstants } from "../../../helpers";
import { useSelector, useDispatch } from "react-redux";
import { leadActions } from "../../../actions";
import { Link} from 'react-router-dom';

export const BlogList = () => {
  const [Items, setItems] = useState([]);
  // const [isOpen, setIsOpen] = useState(false);
  const [UpdateLeadId, UpdateLead] = useState(0);
  const leadState = useSelector((state) => state.lead);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchBlogs();
  }, []);

  function fetchBlogs() {
    fetch(`${appConstants.BASE_URL}api/get-blog`)
      .then((res) => res.json())
      .then((json) => {
        setItems(json.data);
      }, []);
  }

  useEffect(() => {
    if (leadState.leadUpdated === true) {
      alert("Lead updated Successfully!");
      // setIsOpen(false);
      fetchBlogs();
      dispatch(leadActions.updateLeadStatus());
    }
  }, [leadState]);

  const styles = {
    bgColor: {
      backgroundColor: "rgb(177 221 178)",
    },
    noColor: {},
  };

  // const editBlog = function () {
  //   alert("Under Development");
  // };

  return (
    <>
      <div className="container my-3">
        <div className="row">
          <div className="col-md-12">
            <div className="blog-heading">
              <h3 className="my-3">Blog</h3>
              <span style={{marginTop:'10px'}}><Link className="btn btn-primary nav-link" to="/create-blog" >Create Blog</Link></span>
            </div>
            
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Title</th>
                  <th scope="col">Views</th>
                  <th scope="col">status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              {Items ? (
                <tbody>
                  {Items.map((item, index) => (
                    <tr
                      key={index}
                      style={
                        UpdateLeadId === item.lead_id
                          ? styles.bgColor
                          : styles.noColor
                      }
                    >
                      <th>{index + 1}.</th>
                      <td>{item.blog_title}</td>
                      <td>{item.view_count}</td>
                      <td>{item.status == 1 ? "Published" : "Unpublished"}</td>
                      
                      <td>
                        <Link
                          title="Edit Lead"
                          to={`/create-blog?blog_id=${item.blog_id}`}
                        >
                          <img
                            src="./images/edit.png"
                            alt="edit icon"
                            height="20"
                            width="20"
                          />
                        </Link>{" "}
                        &nbsp;
                        
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="3">Loading...</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
