import React, { Component } from 'react';


export class Dashboard extends Component {


  render(){
    return(
      <>
        <h2 className='text-center m-4'>Welcome to Dashboard!</h2>
      </>
    )
  }
}