import { courseService } from '../services';
import {appConstants} from '../helpers';
// import { alertActions } from './';

export const courseActions = {
  courseList,
  getCourseById,
  addCourse,
  updateCourse,
  clearCourse
};

// Get course list
function courseList(data){
  return dispatch => {
    dispatch({type: appConstants.COURSE_LIST_REQUEST}); 
    courseService.courseList(data)
      .then(
        resp => {
          dispatch({
            type: appConstants.COURSE_LIST_SUCCESS,
            course: resp
          });
        },
        error => {
          dispatch({
            type: appConstants.COURSE_LIST_FAILED,
            error
          });
        }
      );
  };
}

// Get course by id
function getCourseById(id){
  return dispatch => {
    dispatch({type: appConstants.COURSE_LIST_REQUEST}); 
    courseService.getCourseById(id)
      .then(
        resp => {
          dispatch({
            type: appConstants.COURSE_DATA_SUCCESS,
            course: resp
          });
        },
        error => {
          dispatch({
            type: appConstants.COURSE_LIST_FAILED,
            error
          });
        }
      );
  };
}

// Add Course
function addCourse(data){
  return dispatch => {
    dispatch({type: appConstants.ADD_COURSE_REQUEST}); 
    courseService.addCourse(data)
      .then(
        resp => {
          dispatch({
            type: appConstants.COURSE_ADDED,
            courseAdded: "success"
          });
          setTimeout(()=>{
            dispatch({
              type: appConstants.COURSE_ADDED,
              courseAdded: ""
            });
          },3000)
        },
        error => {
          dispatch({
            type: appConstants.COURSE_ADD_FAILED,
            error
          });
        }
      );
  };
}

// Update Course
function updateCourse(data){
  return dispatch => {
    dispatch({type: appConstants.UPDATE_COURSE_REQUEST}); 
    courseService.updateCourse(data)
      .then(
        resp => {
          dispatch({
            type: appConstants.COURSE_UPDATED,
            courseUpdated: "success"
          });
          setTimeout(()=>{
            dispatch({
              type: appConstants.COURSE_UPDATED,
              courseUpdated: ""
            });
          },1000)
        },
        error => {
          dispatch({
            type: appConstants.COURSE_ADD_FAILED,
            error
          });
        }
      );
  };
}

// Clear course item data
function clearCourse(){
  return dispatch => {
    dispatch({type: appConstants.CLEAR_COURSE_ITEM}); 
  };
}
