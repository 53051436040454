import React, { Component } from 'react';

export class Register extends Component {
  // constructor(props){
  //   super(props);
  // }

  render(){
    return(
    <h4>Registration no implemented yet!</h4>
    )
  }

}