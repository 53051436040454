import { appConstants } from '../helpers';
import axios from 'axios';

export const leadService = {
	updateLead
};

function updateLead(data) {
	// const requestOptions = {
	// 	method: 'GET',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},
	// 	body: JSON.stringify({
	// 	})
	// };

	return axios.post(`${appConstants.BASE_URL}admin/api/update-lead`,data)
		.then(resp => {
			if(resp.data.code===200){
				return resp.data.data;
			}
			else {
				return resp.data;
			}
		});
}



// function handleResponse(response) {
// 	console.log(response,'responser!!!!!');
// 	return response.then(text => {
// 		const data = text && JSON.parse(text);
// 		if (!response.ok) {
// 			const error = (data && data.message) || response.statusText;
// 			return Promise.reject(error);
// 		}

// 		return data;
// 	});
// }