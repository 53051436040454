import { appConstants } from '../helpers/app-constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function lead(state = initialState, action) {
  switch (action.type) {
    case appConstants.UPDATE_LEAD_REQUEST:
      return {
        leadUpdated: false,
      };
    case appConstants.UPDATE_LEAD_DONE:
      return {
        leadUpdated: true,
      };
    // case appConstants.LOGIN_FAILURE:
    //   return {};
    // case appConstants.LOGOUT:
    //   return {};
    default:
      return state
  }
}