import { appConstants } from '../helpers/app-constants';

export function technology(state = {}, action) {
  
  switch (action.type) {
    case appConstants.TECHNOLOGY_LIST_SUCCESS:
      return {
        ...state,
        technologyItems: action.technology
      };
    case appConstants.TECHNOLOGY_LIST_FAILED:
      return { };
    case appConstants.CLEAR:
      return {};
    default:
      return state
  }
}