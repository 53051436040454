import React, { Component } from 'react';
import { connect } from 'react-redux';

import { batchActions } from '../../../actions';
import { Link } from 'react-router-dom';


class Batches extends Component {

  // constructor(){
  //   super();
  // }

  componentDidMount(){
    this.props.batchList();
  }

  render(){
    const batchItems = this.props.batchItems ? this.props.batchItems : [];
    return(
      <>

        <div className="container my-3">
          <div className="row">
            <div className="col-md-12">
              <h3 className="my-3">Batch List<Link to="/create-batch" className="btn btn-outline-info mx-2"><img src="./images/add.png" alt="edit icon" height="20" width="20"/>&nbsp;Create</Link></h3>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Batch Id</th>
                    <th scope="col">Course Name</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Total Seats</th>
                    <th scope="col">Fee</th>
                    <th scope="col">Days</th>
                    <th scope="col">Timing</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {batchItems?(
                  <tbody>   
                    {batchItems.map((item,index)=>(               
                  <tr key={index}>
                    <th>{index+1}.</th>
                    <td>EDB{item.batch_id}</td>
                    <td>{item.course_title}</td>
                    <td>{new Date(item.start_date).toISOString().substring(0, 10)}</td>
                    <td>{item.total_seats}</td>
                    <td>{item.fee}</td>
                    <td>{item.days}</td>
                    <td>{item.timing}</td>
                    <td>{item.status==="1" ? 'Active' : 'Inactive'}</td>
                    <td><Link to={'/create-batch?id='+item.batch_id}><img src="./images/edit.png" alt="edit icon" height="20" width="20"/></Link></td>
                  </tr>
                   ))}
                  </tbody>
                ):(
                  <tbody>   
                    <tr>
                      <td colSpan="3">Loading...</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapState(state){
  const { batchItems } = state.batch
  return { batchItems }
}

const actionCreators = {
  batchList : batchActions.batchList
}

export default connect(mapState,actionCreators)(Batches);
// export default BatchList
