import { appConstants } from '../helpers/app-constants';

export function blog(state = {}, action) {
  
  switch (action.type) {
    case appConstants.BLOG_LIST_SUCCESS:
      return {
        ...state,
        blogItems: action.blog
      };
    case appConstants.BLOG_LIST_FAILED:
      return { };
    case appConstants.BLOG_ADDED:
      return {
        ...state,
        blogAdded : action.blogAdded
       };
    case appConstants.BLOG_UPDATED:
      return {
        ...state,
        blogUpdated : action.blogUpdated
        };
    case appConstants.BLOG_DATA_SUCCESS:
      return {
        ...state,
        blogItem : action.blog
      }
      case appConstants.CLEAR_BLOG_ITEM:
        return {
          ...state,
          blogItem : {}
        }
  
    default:
      return state
  }
}