import { appConstants } from '../helpers';
import axios from 'axios';

export const blogService = {
	blogList,
	getBlogById,
	addBlog,
	updateBlog
};

const config = {
	headers: {
		 "Content-Type": "application/json", "Authorization": `bearer ${localStorage.getItem('ed_token')}`  
	}
}


function blogList() {


	return axios.get(`${appConstants.BASE_URL}blogapi`)
		.then(blog => {
			if(blog.data.code===200){
				return blog.data.data;
			}
			else {
				return blog.data;
			}
		});
}

// Get blog by id
function getBlogById(id){
	return axios.get(`${appConstants.BASE_URL}admin/api/blogapi/view/${id}`,config)
	.then(blog => {
		if(blog.data.code===200){
			return blog.data.data;
		}
		else {
			return blog.data;
		}
	});
}


// Add Blog
function addBlog(data) {
	return axios.post(`${appConstants.BASE_URL}blogapi/create`,data)
		.then(resp => {
			if(resp.data.code===201){
				return resp.data.data;
			}
			else {
				return resp.data;
			}
		});
}

// Update blog
function updateBlog(data) {

	return axios.post(`${appConstants.BASE_URL}blogapi/update`,data)
		.then(resp => {
			if(resp.data.code===201){
				return resp.data.data;
			}
			else {
				return resp.data;
			}
		});
}