import { blogService } from '../services';
import {appConstants} from '../helpers';
// import { alertActions } from './';

export const blogActions = {
  blogList,
  getBlogById,
  createBlog,
  updateBlog,
  clearBlog
};

// Get blog list
function blogList(data){
  return dispatch => {
    dispatch({type: appConstants.BLOG_LIST_REQUEST}); 
    blogService.blogList(data)
      .then(
        resp => {
          dispatch({
            type: appConstants.BLOG_LIST_SUCCESS,
            course: resp
          });
        },
        error => {
          dispatch({
            type: appConstants.BLOG_LIST_FAILED,
            error
          });
        }
      );
  };
}

// Get blog by id
function getBlogById(id){
  return dispatch => {
    dispatch({type: appConstants.BLOG_LIST_REQUEST}); 
    blogService.getBlogById(id)
      .then(
        resp => {
          dispatch({
            type: appConstants.BLOG_DATA_SUCCESS,
            course: resp
          });
        },
        error => {
          dispatch({
            type: appConstants.BLOG_LIST_FAILED,
            error
          });
        }
      );
  };
}

// Create Blog
function createBlog(data){
  return dispatch => {
    dispatch({type: appConstants.ADD_BLOG_REQUEST}); 
    blogService.addBlog(data)
      .then(
        resp => {
          dispatch({
            type: appConstants.BLOG_ADDED,
            blogAdded: "success"
          });
          setTimeout(()=>{
            dispatch({
              type: appConstants.BLOG_ADDED,
              blogAdded: ""
            });
          },3000)
        },
        error => {
          dispatch({
            type: appConstants.BLOG_ADD_FAILED,
            error
          });
        }
      );
  };
}

// Update Blog
function updateBlog(data){
  return dispatch => {
    dispatch({type: appConstants.UPDATE_BLOG_REQUEST}); 
    blogService.updateBlog(data)
      .then(
        resp => {
          dispatch({
            type: appConstants.BLOG_UPDATED,
            blogUpdated: "success"
          });
          setTimeout(()=>{
            dispatch({
              type: appConstants.BLOG_UPDATED,
              blogUpdated: ""
            });
          },1000)
        },
        error => {
          dispatch({
            type: appConstants.BLOG_ADD_FAILED,
            error
          });
        }
      );
  };
}

// Clear blog item data
function clearBlog(){
  return dispatch => {
    dispatch({type: appConstants.CLEAR_BLOG_ITEM}); 
  };
}
