import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../actions';
import { history } from '../../helpers';

import { Link} from 'react-router-dom';

class Header extends Component {
  constructor(props){
    super(props);
    this.state = {
      firstName : localStorage.getItem('ed_first_name'),
      lastName : localStorage.getItem('ed_last_name')
    }
    this.logout = this.logout.bind(this);
  }

  logout(){
    this.props.logout();
    history.push('/login');
  }

  render(){
    
    return(
      <header>
        <nav className="navbar navbar-expand-xl navbar-light bg-light main-nav px-3 px-sm-0">
          <div className="container">
              <Link className="navbar-brand" to="/technology">
                <img src="./images/logo.png" className="img-fluid" style={{"width": "120px", "backgroundColor": "#ffffff","padding": "10px"}} alt="logo" />
                </Link>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
                aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button>
              <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                <ul className="navbar-nav mr-auto mt-2 mt-lg-0 pl-3">
                  {/* <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#/" data-toggle="dropdown">
                      Payments <img className="droparrow" src="./images/dropdown-icon.png" alt="drop arrow " />
                    </a>
                      <div className="dropdown-menu dropdownBox">
                        <a className="dropdown-item" href="#/">Pending</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#/">Unsuccessfull</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#/">All</a>
                      </div>
                    </li> */}
                    <li className="nav-item"> <Link to="/technology" className="nav-link">Technology</Link> </li>
                    <li className="nav-item"> <Link className="nav-link" to="/courses">Courses</Link> </li>
                    <li className="nav-item"> <Link className="nav-link" to="/batches">Batches</Link> </li>
                    <li className="nav-item"> <Link to="/users" className="nav-link">Users</Link> </li>
                    <li className="nav-item"> <Link to="/leads" className="nav-link">Leads</Link> </li>
                    {/* <li className="nav-item"> <Link to="/blog" className="nav-link">Blog</Link> </li> */}

                </ul>
                {/* <ul className="navbar-nav">
                  <li className="nav-item wSearch">
                    <img src="./images/wsearch.svg" alt="search image" className="wSearch-icon" style={{height: '16px',width: '17px'}} />
                  </li>
                </ul> */}

                <ul id="riteNav" className="nav nav-pills">
              
                  <li className="nav-item active dropdown">
                    <a className="nav-link dropdown-toggle" href="#/" data-toggle="dropdown" style={{cursor: 'pointer'}}>
                      <img  src="./images/default-user.png" alt="user" style={{borderRadius: '100%', height: '32px', 'width': '32px'}} />&nbsp;
                       {this.state.firstName} {this.state.lastName} <img src="./images/dropdown-icon.png" style={{height:'auto'}} alt="dropdown icon"/>
                    </a>
                    <div className="nav-item dropdown">
                              
                      <div className="dropdown-menu dropdown-menu-right dropdownBox">
                        <img className="topArrow" alt="top arrow" src="./images/topArrow.png" />
                        <a href="#/" className="dropdown-item">
                          <img src="./images/admin.jpg" alt="admin" />
                          Profile</a>
                        <a href="#/" className="dropdown-item">
                          <img src="./images/setting.jpg" alt="setting"/>Admin Settings
                        </a>
                        <a href="#/"  className="dropdown-item" onClick={this.logout}>
                          <img src="./images/logout.jpg" alt="logout"/>Log Out
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>

              </div>
          </div>
        </nav>
        <div className="container searchbardiv" id="formsearch">
          <div className="input-group col-md-4 offset-md-8">
            <input type="text" id="searchbox" className="form-control" name="s" />
            <div className="input-group-btn">
              <button className="btn btn-default"  id="searchsubmit"  type="submit"><strong>Search</strong></button>
              </div>
          </div>
        </div>
      </header>

    )
  }
}


function mapState(state) {
	const { loggingIn } = state.authentication;
	return { loggingIn };
}

const actionCreators = {
	logout: userActions.logout
};

const connectedHeaderPage = connect(mapState, actionCreators)(Header);
export { connectedHeaderPage as Header };
