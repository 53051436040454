import { appConstants } from '../helpers';
import axios from 'axios';

export const technologyService = {
	technologyList
};

function technologyList() {
	// const requestOptions = {
	// 	method: 'GET',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},
	// 	body: JSON.stringify({
	// 	})
	// };

	return axios.get(`${appConstants.BASE_URL}technology`)
		.then(technology => {
			if(technology.data.code===200){
				return technology.data.data;
			}
			else {
				return technology.data;
			}
		});
}



// function handleResponse(response) {
// 	console.log(response,'responser!!!!!');
// 	return response.then(text => {
// 		const data = text && JSON.parse(text);
// 		if (!response.ok) {
// 			const error = (data && data.message) || response.statusText;
// 			return Promise.reject(error);
// 		}

// 		return data;
// 	});
// }