import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../actions";

class Login extends Component {
  constructor(props) {
    super(props);
    // this.props.logout();
    this.state = {
      email: "",
      password: "",
      submitted: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
  }
  handleInputChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
    });
  }

  submitLogin(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;

    if (email && password) {
      this.props.login(email, password);
    }
  }

  render() {
    const { email, password, submitted } = this.state;
    const { alert } = this.props;
    return (
      <>
        <form className="p-3 mt-3" name="form">
          {alert && alert.message && (
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          )}
          <div
            className={
              "form-field d-flex align-items-center" +
              (submitted && !email ? " text-danger" : "")
            }
          >
            <span className="far fa-user"></span>
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleInputChange}
            />
          </div>
          <span style={{ marginTop: "15px" }}>
            {submitted && !email && (
              <div className="text-danger">Email is required</div>
            )}
          </span>

          <div
            className={
              "form-field d-flex align-items-center" +
              (submitted && !password ? " text-danger" : "")
            }
          >
            <span className="fas fa-key"></span>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handleInputChange}
            />
          </div>
          <span style={{ marginTop: "-15px" }}>
            {submitted && !password && (
              <div className="text-danger">Password is required</div>
            )}
          </span>
          <button type="button" onClick={this.submitLogin} className="btn mt-3">
            Login
          </button>
        </form>

        {/* <div className="text-center fs-6"> <a href="#">Forget password?</a> or <a href="#">Sign up</a> </div> */}

        {/* <div className="section_bg">
            <div className="row">
              <div className="col-md-5 d-flex login_img">
                <div className="align-self-center">
                  <img alt="logo" src="./images/logo.png" className="img-fluid left-logo" />
                  <img alt="logo" src="./images/user-locked-icon.png" className="img-fluid left-laptop" />
                </div>
              </div>
              <div className="col-md-7 d-flex reset_form login-form">
                <div className="form-right align-self-center">
                  <div className="align_center">
                    <h3>Admin Login</h3>
      
                  </div>
                  <form name="form">
                    <div className={'form-group' + (submitted && !email ? ' text-danger' : '')}>
                      <input type="email" name="email" className="form-control" placeholder="Email" value={this.state.email} onChange={this.handleInputChange}/>
                      {submitted && !email && <div className="help-block">Email is required</div> }
                    </div>
                    <div className={'form-group' + (submitted && !password ? ' text-danger' : '')}>
                      <input type="password" name="password" className="form-control" placeholder="Password" value={this.state.password}	onChange={this.handleInputChange}/>
                      {submitted && !password && <div className="help-block">Password is required</div> }
                    </div>
                    <div className="button-wrap">
                      <button type="button"  onClick={this.submitLogin} className="btn btn-reset btn-lg">Login</button>
                    </div>
                  </form>
                  
                </div>
              </div>
            </div>
          </div> */}
      </>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;

  return { loggingIn, alert };
}

const actionCreators = {
  login: userActions.login,
};

const connectedLoginPage = connect(mapState, actionCreators)(Login);
export { connectedLoginPage as Login };
