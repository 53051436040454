import { leadService } from '../services';
import {appConstants} from '../helpers';
import { alertActions } from './';

export const leadActions = {
	updateLead,
	updateLeadStatus
};

function updateLead(data) {
	return dispatch => {
		dispatch(request({}));

		leadService.updateLead(data)
			.then(
				resp => {
					console.log(resp,'response!!!!');
					dispatch(alertActions.clear());
					dispatch(success());
					// history.push('/');
				},
				error => {
					dispatch(failure(error));
					dispatch(alertActions.error(error));
				}
			);
	};
}

function updateLeadStatus(status){
	return dispatch => {
		dispatch(request({}));
	}
}

	function request() {
		return {
			type: appConstants.UPDATE_LEAD_REQUEST,
		}
	}

	function success() {
		return {
			type: appConstants.UPDATE_LEAD_DONE
		}
	}

	function failure(error) {
		return {
			type: appConstants.LOGIN_FAILURE,
			error
		}
	}
