import { userService } from '../services';
import {appConstants, history} from '../helpers';
import { alertActions } from './';

export const userActions = {
	login,
	logout
};

function login(email, password) {
	return dispatch => {
		dispatch(request({
			email
		}));

		userService.login(email, password)
			.then(
				user => {
					dispatch(alertActions.clear());
					if(user && user.code && user.code===200){ // Set token
						localStorage.setItem('ed_token',user.token);
						localStorage.setItem('ed_uid',user.data.user_id);
						localStorage.setItem('ed_first_name', user.data.first_name);
						localStorage.setItem('ed_last_name', user.data.last_name);
					}
					dispatch(success(user));
					history.push('/');
				},
				error => {
					dispatch(failure(error));
					dispatch(alertActions.error(error));
				}
			);
	};
}

	function logout() {
    userService.logout();
    return { type: appConstants.LOGOUT };
}

	function request(user) {
		return {
			type: appConstants.LOGIN_REQUEST,
			user
		}
	}

	function success(user) {
		return {
			type: appConstants.LOGIN_SUCCESS,
			user
		}
	}

	function failure(error) {
		return {
			type: appConstants.LOGIN_FAILURE,
			error
		}
	}
