import { batchService } from '../services';
import {appConstants} from '../helpers';
// import { alertActions } from './';

export const batchActions = {
  batchList,
  createBatch,
  getBatchById,
  updateBatch
};

// Get course list
function batchList(data){
  return dispatch => {
    dispatch({type: appConstants.BATCH_LIST_REQUEST}); 
    batchService.batchList(data)
      .then(
        resp => {
          dispatch({
            type: appConstants.BATCH_LIST_SUCCESS,
            batch: resp
          });
        },
        error => {
          dispatch({
            type: appConstants.BATCH_LIST_FAILED,
            error
          });
        }
      );
  };
}

// Create Batch
function createBatch(data){
  return dispatch => {
    dispatch({type: appConstants.CREATE_BATCH_REQUEST}); 
    batchService.createBatch(data)
      .then(
        resp => {
          dispatch({
            type: appConstants.CREATE_BATCH_SUCCESS,
            batchCreated: "success"
          });
          setTimeout(()=>{
            dispatch({
              type: appConstants.CREATE_BATCH_SUCCESS,
              batchCreated: ""
            });
          },3000)
        },
        error => {
          dispatch({
            type: appConstants.CREATE_BATCH_FAILED,
            error
          });
        }
      );
  };
}

// Get batch by id
function getBatchById(id){
  return dispatch => {
    dispatch({type: appConstants.BATCH_DATA_REQUEST}); 
    batchService.getBatchById(id)
      .then(
        resp => {
          console.log(resp,'resp!!!!');
          dispatch({
            type: appConstants.BATCH_DATA_SUCCESS,
            batchItem: resp
          });
        },
        error => {
          console.log(error,'err!!!');
          dispatch({
            type: appConstants.CREATE_BATCH_FAILED,
            error
          });
        }
      );
  };
}

/* updateBatch*/
function updateBatch(data){
  return dispatch => {
    dispatch({type: appConstants.UPDATE_BATCH_REQUEST}); 
    batchService.updateBatch(data)
      .then(
        resp => {
          dispatch({
            type: appConstants.BATCH_UPDATED,
            batchUpdated: "success"
          });
          setTimeout(()=>{
            dispatch({
              type: appConstants.BATCH_UPDATED,
              batchUpdated: ""
            });
          },1000)
        },
        error => {
          dispatch({
            type: appConstants.CREATE_BATCH_FAILED,
            error
          });
        }
      );
  };
}

