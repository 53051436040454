import { appConstants } from '../helpers';
import axios from 'axios';

export const courseService = {
	courseList,
	getCourseById,
	addCourse,
	updateCourse
};

const config = {
	headers: {
		 "Content-Type": "application/json", "Authorization": `bearer ${localStorage.getItem('ed_token')}`  
	}
}


function courseList() {


	return axios.get(`${appConstants.BASE_URL}courseapi`)
		.then(course => {
			if(course.data.code===200){
				return course.data.data;
			}
			else {
				return course.data;
			}
		});
}

// Get course by id
function getCourseById(id){
	return axios.get(`${appConstants.BASE_URL}admin/api/course/${id}`,config)
	.then(course => {
		if(course.data.code===200){
			return course.data.data;
		}
		else {
			return course.data;
		}
	});
}


// Add Course
function addCourse(data) {
	return axios.post(`${appConstants.BASE_URL}courseapi/create`,data)
		.then(resp => {
			if(resp.data.code===201){
				return resp.data.data;
			}
			else {
				return resp.data;
			}
		});
}

// Update Course
function updateCourse(data) {

	return axios.post(`${appConstants.BASE_URL}courseapi/update`,data)
		.then(resp => {
			if(resp.data.code===201){
				return resp.data.data;
			}
			else {
				return resp.data;
			}
		});
}