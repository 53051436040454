import React, { useState, useEffect } from "react";
import { appConstants } from "../../../helpers";
import Updatelead from "./update-lead";
import { useSelector, useDispatch } from "react-redux";
import { leadActions } from "../../../actions";

export const Leads = () => {
  const [Items, setItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [UpdateLeadId, UpdateLead] = useState(0);
  const leadState = useSelector((state) => state.lead);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchLeads();
  }, []);

  function fetchLeads() {
    fetch(`${appConstants.BASE_URL}admin/api/get-leads`)
      .then((res) => res.json())
      .then((json) => {
        console.log(json.data, "json.data");
        setItems(json.data);
      }, []);
  }

  useEffect(() => {
    if (leadState.leadUpdated === true) {
      alert("Lead updated Successfully!");
      setIsOpen(false);
      fetchLeads();
      dispatch(leadActions.updateLeadStatus());
    }
  }, [leadState]);

  const styles = {
    bgColor: {
      backgroundColor: "rgb(177 221 178)",
    },
    noColor: {},
  };

  const editLead = function () {
    alert("Under Development");
  };

  const updateLead = (e) => {
    const lead_id = e.currentTarget.getAttribute("data-id");
    UpdateLead(lead_id);
    setIsOpen(true);
  };

  return (
    <>
      <div className="container my-3">
        <div className="row">
          <div className="col-md-12">
            <h3 className="my-3">Leads</h3>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Lead Id</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Course</th>
                  <th scope="col">Qualification</th>
                  <th scope="col">Address</th>
                  <th scope="col">Comments</th>
                  <th scope="col">Status</th>
                  <th scope="col">Last Updated</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              {Items ? (
                <tbody>
                  {Items.map((item, index) => (
                    <tr
                      key={index}
                      style={
                        UpdateLeadId === item.lead_id
                          ? styles.bgColor
                          : styles.noColor
                      }
                    >
                      <th>{index + 1}.</th>
                      <td>{item.lead_id}</td>
                      <td>{item.full_name}</td>
                      <td>{item.email}<br/><strong style={{textTransform: 'capitalize'}}>({item.source})</strong></td>
                      <td>{item.mobile}</td>
                      <td>{item.course_title}</td>
                      <td>{item.highest_qualification}</td>
                      <td>{item.address}</td>
                      <td>{item.comments}</td>
                      <td>{item.status}</td>
                      <td>
                        {new Date(item.updated_date).toLocaleDateString()}
                      </td>
                      <td>
                        <button
                          title="Edit Lead"
                          className="clickable"
                          onClick={editLead}
                        >
                          <img
                            src="./images/edit.png"
                            alt="edit icon"
                            height="20"
                            width="20"
                          />
                        </button>{" "}
                        &nbsp;
                        <button
                          title="Update Lead"
                          className="clickable"
                          data-id={item.lead_id}
                          onClick={updateLead}
                        >
                          <img
                            src="./images/update-icon.png"
                            alt="update icon"
                            height="20"
                            width="20"
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="3">Loading...</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {isOpen && (
        <Updatelead UpdateLeadId={UpdateLeadId} setIsOpen={setIsOpen} />
      )}
    </>
  );
};
