import React from 'react';  
import { Route } from 'react-router-dom';  
  
const LoginLayout = ({ children }) => (                         
  <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="wrapper">
                <div className="logo"> <img src="./images/small-logo.jpg" alt="edhint logo" /> </div>
                <div className="text-center mt-4 name"> edHint </div>
                {children}    
              </div>
            </div>                                             
          </div>
        </div>                                   
    </section>  
  );  
  
  const LoginLayoutRoute = ({component: Component, ...rest}) => {  

    return (  
      <Route exact {...rest} render={matchProps => (  
        <LoginLayout>  
            <Component {...matchProps} />  
        </LoginLayout>  
      )} />  
    )  
  };  
  
export default LoginLayoutRoute; 