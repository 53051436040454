import React, { Component } from 'react';
import { connect } from 'react-redux';

import { courseActions } from '../../../actions';
import { Link } from 'react-router-dom';


class Courses extends Component {

  // constructor(){
  //   super();
  // }

  componentDidMount(){
    this.props.courseList();
  }

  render(){
    const courseItems = this.props.courseItems;
    
    return(
      <>

        <div className="container my-3">
          <div className="row">
            <div className="col-md-12">
              <h3 className="my-3">Courses  <Link to="/add-course" className="btn btn-outline-info mx-2"><img src="./images/add.png" alt="edit icon" height="20" width="20"/>&nbsp;Create</Link></h3>
              
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Introduction</th>
                    <th scope="col">Technology</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Projects</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {courseItems?(
                  <tbody>   
                    {courseItems.map((item,index)=>(               
                  <tr key={index}>
                    <th>{index+1}</th>
                    <td>{item.course_title}</td>
                    <td>{item.introduction}</td>
                    <td>{item.technology_id}</td>
                    <td>{item.duration}</td>
                    <td>{item.projects}</td>
                    <td>{item.status==="1" ? 'Active' : 'Inactive'}</td>
                    <td>
                      <Link to={'/add-course?id='+item.course_id}><img src="./images/edit.png" alt="edit icon" height="20" width="20"/></Link>
                      </td>
                  </tr>
                   ))}
                  </tbody>
                ):(
                  <tbody>   
                    <tr>
                    <td colSpan="3">Loading...</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapState(state){
  const { courseItems } = state.course
  return { courseItems }
}

const actionCreators = {
  courseList : courseActions.courseList
}

export default connect(mapState,actionCreators)(Courses);
// export { connectedCoursesPage as Category };