import { technologyService } from '../services';
import {appConstants} from '../helpers';
// import { alertActions } from './';

export const technologyActions = {
	technologyList
};


function technologyList(data){
  return dispatch => {
    dispatch({type: appConstants.TECHNOLOGY_LIST_REQUEST}); 
    technologyService.technologyList(data)
      .then(
        resp => {
          dispatch({
            type: appConstants.TECHNOLOGY_LIST_SUCCESS,
            technology: resp
          });
        },
        error => {
          dispatch({
            type: appConstants.TECHNOLOGY_LIST_FAILED,
            error
          });
        }
      );
  };
}
