import { appConstants } from '../helpers/app-constants';

export function course(state = {}, action) {
  
  switch (action.type) {
    case appConstants.COURSE_LIST_SUCCESS:
      return {
        ...state,
        courseItems: action.course
      };
    case appConstants.COURSE_LIST_FAILED:
      return { };
    case appConstants.COURSE_ADDED:
      return {
        ...state,
        courseAdded : action.courseAdded
       };
    case appConstants.COURSE_UPDATED:
      return {
        ...state,
        courseUpdated : action.courseUpdated
        };
    case appConstants.COURSE_DATA_SUCCESS:
      return {
        ...state,
        courseItem : action.course
      }
      case appConstants.CLEAR_COURSE_ITEM:
        return {
          ...state,
          courseItem : {}
        }
  
    default:
      return state
  }
}