import React, { useState, useEffect } from "react";
import { appConstants } from "../../../helpers";


export const Users = ()=>{
    const [Items, setItems] = useState([]);
    useEffect(()=>{
        fetch(`${appConstants.BASE_URL}admin/api/user-list`)
        .then((res)=> res.json())
        .then((json)=>{
            setItems(json.data);
        }).catch(err=>{
            console.error(err,'catch block promise');
        })    
    },[]);
    return <>
        <div className="container my-3">
          <div className="row">
            <div className="col-md-12">
              <h3 className="my-3">User List</h3>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">User Id</th>
                    <th scope="col">Full Name</th>
                    <th scope="col">Email</th>
                  </tr>
                </thead>
                {Items?(
                  <tbody>   
                    {Items.map((item,index)=>(
                  <tr key={index}>
                    <th>{index+1}.</th>
                    <td>{item.user_id}</td>
                    <td>{item.first_name} {item.last_name}</td>
                    <td>{item.email}</td>
                  </tr>
                   ))}
                  </tbody>
                ):(
                  <tbody>   
                    <tr>
                      <td colSpan="3">Loading...</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
    </>
}