import { combineReducers } from 'redux';

import { authentication } from './auth.reducer';
// import { register } from './register.reducer';
import { alert } from './alert.reducer';
import { technology } from './technology.reducer';
import { course } from './course.reducer';
import { batch } from './batch.reducer';
import { lead } from './lead.reducer';
import { blog } from './blog.reducer';

const rootReducer = combineReducers({
  authentication,
  alert,
  technology,
  course,
  batch,
  lead,
  blog
});

export default rootReducer;