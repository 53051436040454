import React,{ useState } from 'react';
import styles from './Modal.module.css';
import { useDispatch } from 'react-redux';
import { leadActions } from '../../../actions';
// import DatePicker from "react-datepicker";
const Updatelead = ({UpdateLeadId,setIsOpen}) =>{
    const dispatch = useDispatch();
    const [inputField , setInputField] = useState({
        comments: '',
        user_id: localStorage.getItem('ed_uid'),
        lead_id:UpdateLeadId,
        follow_up_date: '',
        status:''
    })

    const inputsHandler = (e) =>{
        setInputField( {...inputField, [e.target.name]: e.target.value} )
    }

    // const dateHandler = (e) =>{
    //     const timestamp = new Date(e).getTime();
    //     const getYear = new Date(timestamp).getYear();
    //     const getMonth = new Date(timestamp).getMonth();
    //     const getDay = new Date(timestamp).getDay();
    //     var dateString = `${getYear}-${getMonth}-${getDay}`;
    //     setInputField( {...inputField, follow_up_date: dateString} )
    // }

    const saveUpdate = () =>{
        // inputField.follow_up_date = new Date(inputField.follow_up_date);
        // console.log(inputField,'ionput fielad'); return;
        dispatch(leadActions.updateLead(inputField));
    }
    return (
        <>
        <div className={styles.darkBG} onClick={()=> setIsOpen(false)} />
        <div className={styles.centered}>
            <div className={styles.modal}>
                <div className={styles.modalHeader}>
                    <h5 className={styles.heading}>Update Lead</h5>
                </div>
                <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>x</button>
                <div className={styles.modalContent}>
                <form className={styles.form}>
                    <div className="form-group">
                        <label htmlFor="Name">Comments</label>
                        <textarea name="comments" className="form-control" onChange={inputsHandler}  id="comments" placeholder="Enter Comments..." ></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <select onChange={inputsHandler} className='form-control' name="status" id="status">
                            <option value="">Select</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Not Interested">Not Interested</option>
                            <option value="Failed">Failed</option>
                            <option value="Done">Done</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="status">Follow up Date</label>
                        <input name="follow_up_date" className="form-control" onChange={inputsHandler}  id="follow_up_date" placeholder="YYYY-mm-dd" />
                    </div>

                    <button type="button" className='btn btn-default' onClick={saveUpdate}>Save</button>
                </form>
                </div>
            </div>
        </div>

        {/* <div className="modal">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                ...
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> */}
        </>
    )
}

export default Updatelead;