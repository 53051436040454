import React, { Component } from 'react';
import { connect } from 'react-redux';

import { batchActions, courseActions } from '../../../actions';
// import { Link } from 'react-router-dom';
import  FormValidator from '../../../helpers/FormValidator';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { Redirect } from 'react-router-dom';

class CreateBatch extends Component {

  constructor(){
    super();

    this.validator = new FormValidator([
      {
        field: 'courseId',
        method: 'isEmpty',
        validWhen: false,
        message: 'Select course to proceed'
      },
      {
        field: 'startDate',
        method: 'isEmpty',
        validWhen: false,
        message: 'Choose start date to proceed'
      },
      {
        field: 'fee',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter fee to proceed'
      },
      {
        field: 'totalSeats',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter seats to proceed'
      },
      {
        field: 'days',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter days to proceed'
      },
      {
        field: 'timing',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter batch timing to proceed'
      }


      ]);

    this.state = {
      batchId : '',
      courseId : "",
      startDate : "",
      fee : "",
      totalSeats : "",
      status : "",
      days : "",
      timing : "",
      validation: this.validator.valid(),
      redirect:'batches'
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.createBatch = this.createBatch.bind(this);
  }

  componentDidMount(){
    const query = new URLSearchParams(this.props.location.search);
    if(query.get('id')){
      this.setState({
        batchId :query.get('id')
      });
      this.props.getBatchById(query.get('id'));
      if(this.props.batchItem){
        this.setState({
          courseId : this.props.batchItem.course_id,
          startDate : new Date(this.props.batchItem.start_date),
          fee : this.props.batchItem.fee,
          totalSeats : this.props.batchItem.total_seats,
          status : this.props.batchItem.status,
          days : this.props.batchItem.days,
          timing : this.props.batchItem.timing
        })
      }
    }
    this.props.courseList();
  }

  handleInputChange(e) {
		let name = e.target.name;
    let value = e.target.value;
		this.setState({
			[name]: value
    });
  }

  handleDateChange(date) {
		this.setState({
			startDate: date
    });
  }

  createBatch(e){
    e.preventDefault();
    
    const validation = this.validator.validate(this.state);
    this.setState({ submitted: true, validation:validation });
    if (validation.isValid) {
      if(this.state.batchId){
        this.props.updateBatch(this.state);
      }
      else {
        this.props.createBatch(this.state);
      }
      
    }
  }

  render(){
    const courseItems = this.props.courseItems ? this.props.courseItems : [];
    
    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation

    if(this.props.batchCreated==="success" || this.props.batchUpdated==="success"){
      alert(this.state.batchId ? "Batch Updated Successfully" : "Batch Added Successfully");
      return <Redirect to={this.state.redirect} />
    }

    return(
      <>
        <div className="container my-3">
          <div className="row">
            <div className="col-md-12">
              <h3 className="my-3">Create Batch</h3>
              <form>
              <div className="row">
              <div className="col-md-3">
                  <div className="form-group text-left" >
                    <label htmlFor="name">Select Course</label>
                    <select value={this.state.courseId} onChange={this.handleInputChange} className="form-control border-left-0 border-top-0 border-right-0 rounded-0" name="courseId">
                      <option>SELECT</option>
                      {courseItems.map((item,index)=>(
                        <option key={index}  value={item.course_id}>{item.course_title}</option>
                      ))}
                    </select>
                    <span className="text-danger text-danger-validation">{validation.courseId.message}</span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group text-left" >
                    <label htmlFor="name">Choose Start Date</label><br/>
                      <DatePicker
                        name="startDate"
                        selected={ this.state.startDate }
                        onChange={this.handleDateChange}
                        dateFormat="MM/dd/yyyy"
                        className="form-control"
                    />
                    <span className="text-danger text-danger-validation">{validation.startDate.message}</span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group text-left" >
                    <label htmlFor="name">Fee</label>
                    <input type="text" value={this.state.fee} onChange={this.handleInputChange} className='form-control border-left-0 border-top-0 border-right-0 rounded-0'
                      placeholder="Enter fee" name="fee"/>
                    <span className="text-danger text-danger-validation">{validation.fee.message}</span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group text-left" >
                    <label htmlFor="name">Total Seats</label>
                    <input type="text" value={this.state.totalSeats} onChange={this.handleInputChange} className='form-control border-left-0 border-top-0 border-right-0 rounded-0'
                      placeholder="Enter seats" name="totalSeats"/>
                      <span className="text-danger text-danger-validation">{validation.totalSeats.message}</span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group text-left" >
                    <label htmlFor="name">Days of week</label>
                    <input type="text" value={this.state.days} onChange={this.handleInputChange} className='form-control border-left-0 border-top-0 border-right-0 rounded-0'
                      placeholder="Enter Days" name="days"/>
                      <span className="text-danger text-danger-validation">{validation.days.message}</span>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group text-left" >
                    <label htmlFor="name">Timing</label>
                    <input type="text" value={this.state.timing} onChange={this.handleInputChange} className='form-control border-left-0 border-top-0 border-right-0 rounded-0'
                      placeholder="Enter timing" name="timing"/>
                      <span className="text-danger text-danger-validation">{validation.timing.message}</span>
                  </div>
                </div>


                <div className="col-md-3">
                  <div className="form-group text-left" >
                    <label htmlFor="name">Select Status</label>
                    <select name="status" value={this.state.status} onChange={this.handleInputChange} className="form-control border-left-0 border-top-0 border-right-0 rounded-0">
                      <option>SELECT</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-1">
                  {/* <div className="text-left" > */}
                    <button className="form-control btn btn-primary" onClick={this.createBatch} value="Submit">Save</button>
                  {/* </div> */}
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapState(state){
  const { courseItems } = state.course;
  const {batchCreated, batchUpdated, batchItem } = state.batch;
  return { courseItems, batchCreated, batchUpdated, batchItem }
}

const actionCreators = {
  courseList : courseActions.courseList,
  createBatch : batchActions.createBatch,
  getBatchById : batchActions.getBatchById,
  updateBatch : batchActions.updateBatch
}

export default connect(mapState,actionCreators)(CreateBatch);
// export default CreateBatch
