import { appConstants } from '../helpers/app-constants';

export function batch(state = {}, action) {
  
  switch (action.type) {
    
    case appConstants.BATCH_LIST_SUCCESS:
      return {
        ...state,
        batchItems: action.batch
      };
    case appConstants.BATCH_LIST_FAILED:
      return { };
    case appConstants.CREATE_BATCH_SUCCESS:
      return {
        ...state,
        batchCreated : action.batchCreated
       };
    case appConstants.CREATE_BATCH_FAILED:
      return {
        ...state,
        error : action.error
      };
    case appConstants.BATCH_DATA_REQUEST:
      return {
        ...state,
        isLoading : true
      };
      case appConstants.BATCH_DATA_SUCCESS:
      return {
        ...state,
        batchItem : action.batchItem,
        isLoading : false
      };
    case appConstants.BATCH_DATA_FAILED:
      return {
        ...state,
        error : action.error
      };
    case appConstants.BATCH_UPDATED:
      return {
        ...state,
        batchUpdated : action.batchUpdated
        };
    case appConstants.UPDATE_BATCH_REQUEST:
      return {
        ...state,
        isLoading : true
        };
    case appConstants.COURSE_DATA_SUCCESS:
      return {
        ...state,
        courseItem : action.course
      }
    default:
      return state
  }
}