import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import  FormValidator from '../../../helpers/FormValidator';
import  {formatDate} from '../../../helpers/common';

import { blogActions, technologyActions } from '../../../actions';
import { CKEditor } from 'ckeditor4-react';
import { useSearchParams } from 'react-router-dom'

class CreateBlog extends Component {

  constructor(){
    super();

  this.validator = new FormValidator([
      {
        field: 'blog_title',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter Title to proceed'
      },
      {
        field: 'blog_title_id',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter Title Id to proceed'
      },
      {
        field: 'meta_description',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter Meta Desciption to proceed'
      },
      {
        field: 'meta_keywords',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter Meta Keyword to proceed'
      },
      {
        field: 'view_count',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter View Count to proceed'
      },
      {
        field: 'created_date',
        method: 'isEmpty',
        validWhen: false,
        message: 'Enter Created Date to proceed'
      },
      {
        field: 'category_id',
        method: 'isEmpty',
        validWhen: false,
        message: 'Select Category to proceed'
      },
      {
        field: 'status',
        method: 'isEmpty',
        validWhen: false,
        message: 'Select  to proceed'
      }
    ]);


    this.state = {
      id : '',
      blog_title : '',
      blog_title_id: '',
      meta_description : '',
      meta_keywords: '',
      blog_content: '',
      view_count: '',
      youtube_ref : '',
      created_date : formatDate(new Date()),
      category_id : '',
      status: '',
      selectedFile:null,
      submitted : false,
      editorRef: React.createRef(null),
      validation: this.validator.valid()
    }
    console.log(this.state.created_date,'created date');
    this.handleInputChange = this.handleInputChange.bind(this);
    this.createBlog = this.createBlog.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  componentDidMount(){
    const query = new URLSearchParams(document.location.search);
    console.log(query.get('id'),'aaaa');
    if(query.get('id')){
      this.setState({
        id :query.get('id')
      });  
    }
    if(query.get('id')){
      this.props.getCourseById(query.get('id'));
    }
    this.props.technologyList();
  }

//   componentDidUpdate(prevProps, prevState, snapshot) {
//     if(this.props.courseItem && this.props.courseItem !== prevProps.courseItem){
//       this.setState({
//         blog_title : this.props?.courseItem?.blog_title,
//         blog_title_id : this.props?.courseItem?.blog_title_id,
//         meta_description : this.props.courseItem.meta_description,
//         view_count: this.props.courseItem.view_count,
//         youtube_ref: this.props.courseItem.youtube_ref, 
//         created_date : this.props.courseItem.created_date,
//         status : this.props.courseItem.status,
//       })
//     }
    
// }

  handleInputChange(e) {
		let name = e.target.name;
    let value = e.target.value;
    if(name === "blog_title"){
      this.setState({blog_title_id: value.split(' ').join('-')});
    }

		this.setState({
			[name]: value
    });

  }

  handleInputChange2(blog_content) {
		this.setState({
			blog_content: blog_content
    });
  }


  // On file select...
  onFileChange(event) {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  createBlog(e){
    e.preventDefault();
    const validation = this.validator.validate(this.state);
    this.setState({ submitted: true, validation:validation });
    if (validation.isValid) {
      const formData = new FormData();
      if(this.state.selectedFile && this.state.selectedFile.name){
        formData.append("file", this.state.selectedFile, this.state.selectedFile.name);
      }
      formData.append("blog_title", this.state.blog_title);
      formData.append("blog_title_id", this.state.blog_title_id.split(" ").join("-").toLowerCase());
      formData.append("meta_description", this.state.meta_description);
      formData.append("meta_keywords", this.state.meta_keywords);
      formData.append("view_count", this.state.view_count);
      formData.append("youtube_ref", this.state.youtube_ref);
      formData.append("created_date", this.state.created_date);
      // formData.append("created_by", localStorage.getItem('ed_uid'));
      formData.append("category_id", this.state.category_id);
      formData.append("status", this.state.status);
      formData.append("blog_content", this.state.blog_content);
      if(this.state.id){
        formData.append("blog_id", this.state.id);
        this.props.updateBlog(formData);
      }
      else {
        formData.append("created_by", localStorage.getItem('ed_uid'));
        this.props.createBlog(formData);
      }
    }
  }

  componentWillUnmount(){
    this.props.clearBlog(); //example method to clean data
 }

  render(){
    const technologyItems = this.props.technologyItems;
    
    if(this.props.blogAdded==="success" || this.props.blogUpdated==="success"){
      alert(this.state.id ? "Blog Updated Successfully" : "Blog Created Successfully");
      return <Redirect to="blog" />
    }

    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation
    return(
      <>
        <div className="container my-3">
          <h3 className="my-3">{this.state.id ?( <span>Edit</span>) :(<span>Create</span>)} Blog</h3>
          <form>
            <div className="row">

              <div className="col-md-12">
                <div className="form-group text-left" >

                  <label htmlFor="name">Description</label>
                    {/* Editor */}
                    <CKEditor
                    name="blog_content"
                    initData="<p>Blog starts here</p>"
                    onChange={ ( { editor } ) => {
                      this.handleInputChange2(editor.getData());
                  } }
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group text-left" >
                  <label htmlFor="name">Title(Max length: 70)</label>
                  <input type="text" maxLength={70} id="blog_title" className={validation.blog_title.isInvalid ? 'form-control border-left-0 border-top-0 border-right-0 rounded-0 alert-warning' : 'form-control border-left-0 border-top-0 border-right-0 rounded-0'}
                    placeholder="Enter Title"  value={this.state.blog_title || ''} onChange={this.handleInputChange} name="blog_title"/>
                    <span className="text-danger text-danger-validation">{validation.blog_title.message}</span>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group text-left" >
                  <label htmlFor="name">Title Id</label>

                  <input type="text" id="blog_title_id" className={validation.blog_title_id.isInvalid ? 'form-control border-left-0 border-top-0 border-right-0 rounded-0 alert-warning' : 'form-control border-left-0 border-top-0 border-right-0 rounded-0'}
                    placeholder="Enter Title"  value={this.state.blog_title_id || ''} onChange={this.handleInputChange} name="blog_title_id"/>
                    <span className="text-danger text-danger-validation">{validation.blog_title_id.message}</span>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group text-left" >
                  <label htmlFor="name">Meta Description(Max length: 160)</label>

                  <textarea maxLength={160} type="text" id="meta_description" className={validation.meta_description.isInvalid ? 'form-control border-left-0 border-top-0 border-right-0 rounded-0 alert-warning' : 'form-control border-left-0 border-top-0 border-right-0 rounded-0'}
                    placeholder="Enter Title"  value={this.state.meta_description || ''} onChange={this.handleInputChange} name="meta_description"> </textarea>
                    <span className="text-danger text-danger-validation">{validation.meta_description.message}</span>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group text-left" >
                  <label htmlFor="name">Enter Keywords</label>
                  <input type="text" className={validation.meta_keywords.isInvalid ? 'form-control border-left-0 border-top-0 border-right-0 rounded-0 alert-warning' : 'form-control border-left-0 border-top-0 border-right-0 rounded-0'}
                    placeholder="Enter keywords"  value={this.state.meta_keywords} onChange={this.handleInputChange} name="meta_keywords"/>
                    <span className="text-danger text-danger-validation">{validation.meta_keywords.message}</span>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group text-left" >
                    <label htmlFor="name">View Count</label>
                    <input type="text" className={validation.view_count.isInvalid ? 'form-control border-left-0 border-top-0 border-right-0 rounded-0 alert-warning' : 'form-control border-left-0 border-top-0 border-right-0 rounded-0'}
                      placeholder="Enter view count"  value={this.state.view_count} onChange={this.handleInputChange} name="view_count"/>
                      <span className="text-danger text-danger-validation">{validation.view_count.message}</span>
                  </div>
              </div>

              <div className="col-md-3">
                <div className="form-group text-left" >
                    <label htmlFor="name">YouTube ref</label>
                    <input type="text" className={'form-control border-left-0 border-top-0 border-right-0 rounded-0'}
                      placeholder="Enter Youtube Ref"  value={this.state.youtube_ref} onChange={this.handleInputChange} name="youtube_ref"/>
                  </div>
              </div>

              <div className="col-md-3">
                <div className="form-group text-left" >
                    <label htmlFor="name">Created Date</label>
                    <input type="text" className={'form-control border-left-0 border-top-0 border-right-0 rounded-0'}
                      placeholder="Created Date"  value={this.state.created_date} onChange={this.handleInputChange} name="created_date"/>
                  </div>
              </div>

              <div className="col-md-3">
                <div className="form-group text-left" >
                  <label htmlFor="name">Banner Image</label>
                  <input type="file" className="form-control border-left-0 border-top-0 border-right-0" name="avatar" onChange={this.onFileChange} />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group text-left" >
                  <label htmlFor="name">Technology</label>
                  {technologyItems?(
                    <select className="form-control" value={this.state.category_id} onChange={this.handleInputChange} name="category_id">
                      <option>-Select-</option>
                      {technologyItems.map((item,index)=>(
                        <option key={index}  value={item.technology_id}>{item.name}</option>
                      ))}
                      
                    </select>
                  ):(
                    <p>Loading...</p>
                  )}
                  <span className="text-danger text-danger-validation">{validation.category_id.message}</span>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group text-left" >
                  <label htmlFor="name">Status</label>
                    <select className="form-control" value={this.state.status} onChange={this.handleInputChange} name="status">
                      <option>-Select-</option>
                      <option value="1">Published</option>
                      <option value="0">Unpublished</option>                      
                      
                    </select>
                  <span className="text-danger text-danger-validation">{validation.status.message}</span>
                </div>
              </div>



              <div className='clearfix'></div>
              <p className="text-center mt-4">
                <button type="button" onClick={this.createBlog} className="btn btn-primary mx-2">Submit</button>
              </p>
                {/* disabled={loading ? true : false}  */}
            {/* </div> */}
          </div>
          </form>

        </div>
      </>
    )
  }
}

function mapState(state){
  const { technologyItems } = state.technology;
  const { blogAdded, blogUpdated, blogItem } = state.blog;
  return { technologyItems, blogAdded, blogUpdated, blogItem }
}

const actionCreators = {
  createBlog : blogActions.createBlog,
  updateBlog : blogActions.updateBlog,
  getBlogById : blogActions.getBlogById,
  clearBlog: blogActions.clearBlog,
  technologyList : technologyActions.technologyList
}

export default connect(mapState,actionCreators)(CreateBlog);
