export const appConstants = {
  BASE_URL: "https://www.edhint.com/", // 'http://localhost/edhint_php/', // 
  // APP_BASE_URL : 'http://localhost:4000/api/',
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR",
  LOGOUT: "USERS_LOGOUT",
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",
  TECHNOLOGY_LIST_SUCCESS: "TECHNOLOGY_LIST_SUCCESS",
  TECHNOLOGY_LIST_REQUEST: "TECHNOLOGY_LIST_REQUEST",
  TECHNOLOGY_LIST_FAILED: "TECHNOLOGY_LIST_FAILED",
  COURSE_LIST_REQUEST: "COURSE_LIST_REQUEST",
  COURSE_LIST_SUCCESS: "COURSE_LIST_SUCCESS",
  COURSE_LIST_FAILED: "COURSE_LIST_FAILED",
  ADD_COURSE_REQUEST: "ADD_COURSE_REQUEST",
  COURSE_ADDED: "COURSE_ADDED",
  COURSE_ADD_FAILED: "COURSE_ADD_FAILED",
  COURSE_DATA_SUCCESS: "COURSE_DATA_SUCCESS",
  UPDATE_COURSE_REQUEST: "UPDATE_COURSE_REQUEST",
  COURSE_UPDATED: "COURSE_UPDATED",
  BATCH_LIST_REQUEST: "BATCH_LIST_REQUEST",
  BATCH_LIST_SUCCESS: "BATCH_LIST_SUCCESS",
  BATCH_LIST_FAILED: "BATCH_LIST_FAILED",
  BATCH_DATA_REQUEST: "BATCH_DATA_REQUEST",
  CREATE_BATCH_REQUEST: "CREATE_BATCH_REQUEST",
  CREATE_BATCH_SUCCESS: "CREATE_BATCH_SUCCESS",
  CREATE_BATCH_FAILED: "CREATE_BATCH_FAILED",
  BATCH_DATA_SUCCESS: "BATCH_DATA_SUCCESS",
  BATCH_DATA_FAILED: "BATCH_DATA_FAILED",
  UPDATE_BATCH_REQUEST: "UPDATE_BATCH_REQUEST",
  BATCH_UPDATED: "BATCH_UPDATED",
  CLEAR_COURSE_ITEM: "CLEAR_COURSE_ITEM",
  UPDATE_LEAD_REQUEST: "UPDATE_LEAD_REQUEST",
  UPDATE_LEAD_DONE: "UPDATE_LEAD_DONE",
  BLOG_LIST_REQUEST: "BLOG_LIST_REQUEST",
  BLOG_LIST_SUCCESS: "BLOG_LIST_SUCCESS",
  BLOG_LIST_FAILED: "BLOG_LIST_FAILED",
  BLOG_DATA_SUCCESS: "BLOG_DATA_SUCCESS",
  ADD_BLOG_REQUEST: "ADD_BLOG_REQUEST",
  BLOG_ADDED: "BLOG_ADDED",
  BLOG_ADD_FAILED: "BLOG_ADD_FAILED",
  UPDATE_BLOG_REQUEST: "UPDATE_BLOG_REQUEST",
  BLOG_UPDATED: "BLOG_UPDATED",
  CLEAR_BLOG_ITEM: "CLEAR_BLOG_ITEM",
};
