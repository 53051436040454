import React from 'react';  
import { Route, Redirect } from 'react-router-dom';  
import { Header } from '../components/shared/header';
  
const LoggedInLayout = ({children, ...rest}) => {  
  return (
    <section>
      <Header />
      <div className="main">{children}</div>  
    </section>
   
  )  
}
  
const LoggedInLayoutRoute = ({component: Component, ...rest}) => {
  return (  
    <Route {...rest} render={matchProps => (
        localStorage.getItem('ed_token')
        ?<LoggedInLayout><Component {...matchProps} /></LoggedInLayout> :
        <Redirect to={{ pathname: '/login', state: { from: matchProps.location } }} />
    //   <LoggedInLayout>  
    //       <Component {...matchProps} />  
    //   </LoggedInLayout>  
    )} />  
  )  
};  
  
export default LoggedInLayoutRoute;